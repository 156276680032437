<script>
import './eventListOrigin.scss'
import Layout from "@/views/layouts/main.vue";
import PageHeader from "@/components/page_header_new.vue";
import appConfig from "@/app.config.json";
import {mapState} from "vuex";
import {loggedInfoObject} from "@/state/helpers";
import axios from "axios";
import {msgConfirmOptCenter, msgModalOptCenter} from "@/components/globalFunctions";
//import noImg from "@/assets/mcimages/no_img.png";

export default {
  page: {
    title: "특매행사 상품관리",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  components: {
    Layout,
    PageHeader,
  },

  data() {
    return {
      deviceId: "9876543210",
      title: "특매행사 상품관리",
      marketName: "크리톡",
      eventType: 0,
      eventGroupList: [],
      noImg: "https://cdn1.critalk.com/static/images/no_image.png",
      selectedEVL: {},
      selectedEVLitemsInRank: [],
      selectedEVLitemsNotInRank: [],
      placeholderValue: "",
      tmpItemlist: [],
      searchvalue: "",
      url: "",
      today: "",
      limit: "",
      manager: "",
      multi: "",
      eventBadgeChkList: [],
      srchBadgeChkList: [],
    };
  },

  filters: {
    comma(val) {
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },

  computed: {
    ...mapState(loggedInfoObject),
  },

  mounted() {
    this.getEventGroupList();
    this.setUrl();
  },

  methods: {
    //날짜 formating
    leftPad(value) {
      if (value >= 10) {
        return value;
      }
      return `0${value}`
    },
    // iframe 설정
    setUrl() {
      let source = new Date()
      const year = source.getFullYear()
      const month = this.leftPad(source.getMonth() + 1)
      const day = this.leftPad(source.getDate())
      this.url = "critalk.com";
      this.today = `${year}` + `${month}` + `${day}`
      //console.log('this.today : ', this.today)
      this.url = `https://market.critalk.com/product?market_srl=${this.logged_info.mart_srl}&env=dev&tdate=${this.today}`;
    },

    // 좌측 이벤트 그룹 가져오기
    getEventGroupList() {
      let url = `/api/web/market/product/event/group/${this.logged_info.biznum}/${this.eventType}`

      axios.get(url)
          .then((res) => {
            //console.log(response.data)
            this.eventGroupList = res.data;
            this.selectedEVL = {};
            this.selectedEVLitemsInRank = [];
            this.selectedEVLitemsNotInRank = [];
          })
          .catch((error) => {
            console.log(error);
          });
    },

    //특매그룹순서 변경때 변경할 순서값 return
    findId(srl) {
      return this.$refs[`${srl}`][0].value;
    },

    //특매그룹순서 변경
    seqModify(toprankSrl, before, after) {
      event.stopPropagation(); //부모el과 자식el이 모두 이벤트를 가지고 있을 때 자식의 이벤트가 부모에게로 전달되지 않게 하기 위한 명령어
      let strtoprankSrl = String(toprankSrl);
      let strBefore = String(before);
      // let params = {product_srl: strPsrl, before_seq: strBefore, after_seq: after}
      // console.log(params)
      //console.log(strtoprankSrl, strBefore, after)
      if (after > this.eventGroupList.length) {
        this.$bvModal.msgBoxOk("목록 갯수 내에서 순서를 정해주세요.", msgModalOptCenter);
        return;
      }
      if (after === "0") {
        this.$bvModal.msgBoxOk("0을 제외한 숫자로 정해주세요.", msgModalOptCenter);
        return;
      }

      this.$bvModal.msgBoxConfirm("해당 특매그룹의 순서를 변경하시겠습니까?", msgConfirmOptCenter)
          .then((sel) => {
            if (sel) {
              let url = `/api/web/market/product/event/group/seq/v2`
              let param = {
                toprank_event_srl: strtoprankSrl,
                before_seq: strBefore,
                after_seq: after,
              }

              axios.put(url, param)
                  .then((result) => {
                    //console.log(result)
                    if (result.data.status === "success") {
                      this.getEventGroupList();
                      this.$refs.iframe.contentWindow.location.replace(this.url);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
            }
          })
    },

    // 이벤트 리스트 상세 설정
    setEventListDetail(evl) {
      if (!evl.visible) {
        this.$bvModal.msgBoxOk("숨김처리된 목록으로 순서를 변경할 수 없습니다.\n숨김처리 해제 후 이용해주세요.", msgModalOptCenter);
        return
      }

      this.selectedEVL = evl;
      this.placeholderValue = "";

      let url = `/api/web/market/product/event/group/items/${this.logged_info.biznum}/${evl.pos_bg_code}`

      axios.get(url)
          .then((response) => {
            //console.log(response.data)
            this.selectedEVLitemsInRank = response.data;

            for (let i = 0; i < this.selectedEVLitemsInRank.length; i++) {
              this.selectedEVLitemsInRank[i].tmp_limit = this.selectedEVLitemsInRank[i].badge_limit === "Y";
              this.selectedEVLitemsInRank[i].tmp_manager = this.selectedEVLitemsInRank[i].badge_manager === "Y";
              this.selectedEVLitemsInRank[i].tmp_multi = this.selectedEVLitemsInRank[i].badge_multi === "Y";
            }

            this.setNextEventListDetail(evl)
          })
          .catch((error) => {
            console.log(error);
          });
    },

    //이벤트 리스트 상세설정 후 요청 api
    setNextEventListDetail(val) {
      let url = `/api/web/market/product/event/group/item/search/${this.logged_info.biznum}/${val.pos_bg_code}`

      axios.get(url)
          .then((response) => {
            //console.log(response.data)
            this.selectedEVLitemsNotInRank = response.data;

            for (let i = 0; i < this.selectedEVLitemsNotInRank.length; i++) {
              this.selectedEVLitemsNotInRank[i].tmp_limit = this.selectedEVLitemsNotInRank[i].badge_limit === "Y";
              this.selectedEVLitemsNotInRank[i].tmp_manager = this.selectedEVLitemsNotInRank[i].badge_manager === "Y";
              this.selectedEVLitemsNotInRank[i].tmp_multi = this.selectedEVLitemsNotInRank[i].badge_multi === "Y";
            }

            this.tmpItemlist = this.selectedEVLitemsNotInRank;
          })
          .catch((error) => {
            console.log(error);
          });
    },

    // top20에 상품 넣기
    putItemInRank(mproduct_event_srl) {
      this.$bvModal.msgBoxConfirm("해당 상품을 TOP20목록에 넣으시겠습니까?", msgConfirmOptCenter)
          .then((sel) => {
            if (sel) {
              let url = `/api/web/market/product/event/group/items/${this.logged_info.biznum}/${this.selectedEVL.pos_bg_code}`
              let param = {
                mproduct_event_srl: mproduct_event_srl,
              }

              axios.post(url, param)
                  .then(() => {
                    this.setEventListDetail(this.selectedEVL);
                    // this.url = `https://market.critalk.com/product?market_srl=${this.logged_info.mart_srl}&tdate=${this.today}`
                    //this.setUrl()
                    this.$refs.iframe.contentWindow.location.replace(this.url);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
            }
          })
    },

    //특매그룹순서 변경
    seqModifyInSEVL(topRankSrl, before, after) {
      let strtoprankSrl = String(topRankSrl);
      let strBefore = String(before);
      // let params = {product_srl: strPsrl, before_seq: strBefore, after_seq: after}
      // console.log(params)
      //console.log("seqModifyInSEVL" + strtoprankSrl, strBefore, after)
      if (after > this.selectedEVLitemsInRank.length) {
        this.$bvModal.msgBoxOk("TOP20에 등록한 상품 갯수 내에서 순서를 정해주세요.", msgModalOptCenter);
        return;
      }
      if (after === "0") {
        this.$bvModal.msgBoxOk("0을 제외한 숫자로 정해주세요.", msgModalOptCenter);
        return;
      }

      this.$bvModal.msgBoxConfirm("해당 상품의 순서를 변경하시겠습니까?", msgConfirmOptCenter)
          .then((sel) => {
            if (sel) {
              let url = `/api/web/market/product/event/group/items/seq/v2`
              let param = {
                toprank_srl: strtoprankSrl,
                before_seq: strBefore,
                after_seq: after
              }

              axios.put(url, param)
                  .then((result) => {
                    if (result.data.status === "success") {
                      //console.log(result)
                      this.setEventListDetail(this.selectedEVL);
                      this.$refs.iframe.contentWindow.location.replace(this.url);
                    } else {
                      this.$bvModal.msgBoxOk("해당 상품의 순서를 변경하지 못했습니다.\n다시 시도해 주세요.", msgModalOptCenter);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
            }
          })
    },

    // 상품 삭제하기
    deleteTheProduct(toprank_srl) {
      this.$bvModal.msgBoxConfirm("해당 상품을 TOP20에서 삭제하시겠습니까?", msgConfirmOptCenter)
          .then((sel) => {
            if (sel) {
              let url = `/api/web/market/product/event/group/items/${this.logged_info.biznum}/${this.selectedEVL.pos_bg_code}`
              let param = {
                data: {
                  toprank_srl: toprank_srl
                }
              }

              axios.delete(url, param)
                  .then((result) => {
                    if (result.data.status === "success") {
                      this.setEventListDetail(this.selectedEVL);
                      this.$refs.iframe.contentWindow.location.replace(this.url);
                    } else {
                      this.$bvModal.msgBoxOk("해당 상품을 TOP20목록에서 삭제하지 못했습니다.\n다시 시도해 주세요.", msgModalOptCenter);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
            }
          })
    },

    // 품절 confirm 창
    confirmSoldOut(val, best = true) {
      console.log('LOG ----- :', val)

      let title = ''
      let tmpSoldOut = val.soldout === 'Y' ? 'N' : 'Y'
      if (tmpSoldOut !== 'N') {
        title = '해당 상품을 품절처리 하시겠습니까?'
      } else {
        title = '해당 상품을 품절해제 하시겠습니까?'
      }

      this.$bvModal.msgBoxConfirm(title, msgConfirmOptCenter)
          .then((sel) => {
            console.log('sel', sel, tmpSoldOut)

            if (sel) {
              let url = `/api/openmarket/product/market/soldout/98/9876543210`
              let param = {
                soldout: tmpSoldOut,
                product_srl: val.mproduct_srl
              }

              axios.put(url, param)
                  .then((res) => {
                    console.log('res :', res.data)

                    this.setEventListDetail(this.selectedEVL);

                    if (best) {
                      this.$bvModal.hide(`info${val.toprank_srl}`)
                    } else {
                      this.$bvModal.hide(`nsevl${val.mproduct_srl}`)
                    }

                  })
                  .catch(
                      err => console.log('Sold Out Error :', err)
                  )
            }
          })
    },

    // 다른 component들이 영향가지 않도록 막기
    noBubbling() {
      event.stopPropagation();
    },

    // 상품 검색하기
    searchProduct() {
      console.log(this.$refs.searchKeyword.value);
      event.stopPropagation()

      if (this.$refs.searchKeyword.value === "") {
        this.selectedEVLitemsNotInRank = this.tmpItemlist;
      }

      let ind = 0;
      let tmplist = [];

      for (let i = 0; i < this.tmpItemlist.length; i++) {
        if (this.tmpItemlist[i].doha_pname.includes(this.$refs.searchKeyword.value)) {
          //console.log(this.tmpItemlist[i])
          tmplist[ind] = this.tmpItemlist[i];
          ind++;
        } else if (this.tmpItemlist[i].barcode.includes(this.$refs.searchKeyword.value)) {
          tmplist[ind] = this.tmpItemlist[i];
          ind++;
        } else if (this.tmpItemlist[i].pos_pname.includes(this.$refs.searchKeyword.value)) {
          tmplist[ind] = this.tmpItemlist[i];
          ind++;
        }
      }
      if (typeof tmplist.length !== "undefined") {
        this.selectedEVLitemsNotInRank = tmplist;
      }
    },

    //
    refreshItemList() {
      this.selectedEVLitemsNotInRank = this.tmpItemlist;
      this.$refs.searchKeyword.value = "";
    },

    //뱃지 수정 및 한줄글 수정
    editItem(val, wherefrom, modalId) {
      event.stopPropagation(); //부모el과 자식el이 모두 이벤트를 가지고 있을 때 자식의 이벤트가 부모에게로 전달되지 않게 하기 위한 명령어

      let url = `/api/openmarket/product/market/${this.logged_info.mart_srl}/9876543210`;

      let param = {
        product_srl: val.mproduct_srl,
        comment: val.comment,
        badge_limit: this.changeParam("limit", val.tmp_limit),
        badge_manager: this.changeParam("manager", val.tmp_manager),
        badge_multi: this.changeParam("multi", val.tmp_multi),
      };

      console.log("url, param", url, param);

      axios.put(url, param)
          .then((res) => {
            console.log(res.data);

            if (res.data.status === "success") {
              if (wherefrom === "ranked") {
                for (let i = 0; i < this.selectedEVLitemsInRank.length; i++) {
                  if (this.selectedEVLitemsInRank[i].mproduct_srl === val.mproduct_srl) {
                    this.selectedEVLitemsInRank[i].comment = val.comment;
                    this.selectedEVLitemsInRank[i].badge_limit = this.changeParam("limit", val.tmp_limit);
                    this.selectedEVLitemsInRank[i].badge_manager = this.changeParam("manager", val.tmp_manager);
                    this.selectedEVLitemsInRank[i].badge_multi = this.changeParam("multi", val.tmp_multi);
                  }
                }
              } else if (wherefrom === "unranked") {
                for (let ind = 0; ind < this.selectedEVLitemsNotInRank.length; ind++) {
                  if (this.selectedEVLitemsNotInRank[ind].mproduct_srl === val.mproduct_srl) {
                    this.selectedEVLitemsNotInRank[ind].comment = val.comment;
                    this.selectedEVLitemsNotInRank[ind].badge_limit = this.changeParam("limit", val.tmp_limit);
                    this.selectedEVLitemsNotInRank[ind].badge_manager = this.changeParam("manager", val.tmp_manager);
                    this.selectedEVLitemsNotInRank[ind].badge_multi = this.changeParam("multi", val.tmp_multi);
                  }
                }
              }
              //this.setEventlistDetail(this.selectedEVL)
              this.$refs.iframe.contentWindow.location.replace(this.url);

              this.$bvModal.hide(modalId)
            } else {
              this.$bvModal.msgBoxOk("상품 정보 변경 실패하였습니다.", msgModalOptCenter);
            }
          })
          .catch(
              (err) => console.log(err)
          );
    },

    // param 바꿔주기
    changeParam(chk, val) {
      switch (chk) {
        case "limit": {
          if (val === true) {
            this.limit = "Y";
          } else {
            this.limit = "N";
          }

          return this.limit;
        }

        case "manager": {
          if (val === true) {
            this.manager = "Y";
          } else {
            this.manager = "N";
          }

          return this.manager;
        }

        case "multi": {
          if (val === true) {
            this.multi = "Y";
          } else {
            this.multi = "N";
          }

          return this.multi;
        }
      }
    },

    //행사그룹노출여부
    setCondition(evl) {
      console.log(evl.visible)
      console.log(evl)

      let url = `/api/web/market/product/event/invizible/${this.logged_info.biznum}/${evl.pos_bg_code}`

      if (evl.visible) {
        let param = {
          data: {
            biznum: this.logged_info.biznum,
            pos_bg_code: evl.pos_bg_code
          }
        }

        axios.delete(url, param)
            .then((response) => {
              console.log(response.data)
              if (response.data.status === "success") {
                this.$bvModal.msgBoxOk('"' + evl.title + '"의 모든 상품들이 상품목록에서 보여집니다.', msgModalOptCenter);
              } else {
                this.$bvModal.msgBoxOk('"' + evl.title + '"의 숨김처리 취소가 실패하였습니다.\n다시 시도해주세요.', msgModalOptCenter);
              }
              this.$refs.iframe.contentWindow.location.replace(this.url);
            })
            .catch(error => {
              console.log(error)
            })
      } else if (!evl.visible) {
        const fd = new FormData
        fd.append("biznum", this.logged_info.biznum)
        fd.append("pos_bg_code", evl.visible)

        axios.post(url, fd)
            .then((response) => {
              console.log(response.data)
              if (response.data.status === "success") {
                this.$bvModal.msgBoxOk('"' + evl.title + '"의 모든 상품들이 상품목록에서 숨김처리되었습니다.', msgModalOptCenter);
                this.selectedEVL = {};
                this.selectedEVLitemsInRank = "";
                this.selectedEVLitemsNotInRank = "";
              } else {
                this.$bvModal.msgBoxOk('"' + evl.title + '"의 숨김처리가 실패하였습니다.\n다시 시도해주세요.', msgModalOptCenter);
              }
              this.$refs.iframe.contentWindow.location.replace(this.url);
            })
            .catch(error => {
              console.log(error)
            })
      }
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>
    <div class="row">
      <!-- 기본정보 영역 -->
      <div class="col-md-2-5">
        <div class="card">
          <div class="card-header">
            <label class="mb-0" style="font-size: 1.2em; font-weight: bold">
              등록되어있는 특매그룹
            </label>
          </div>

          <div class="card-body" style="padding: 20px 20px 0;">

            <!-- 특매그룹 -->
            <div class="col-12 btn-group mb-3" role="group">
              <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" value="0"
                     v-model="eventType" @change="getEventGroupList" checked/>
              <label class="btn btn-outline-secondary" for="btnradio1">
                일반특매
              </label>

              <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" value="1"
                     v-model="eventType" @change="getEventGroupList"/>
              <label class="btn btn-outline-secondary" for="btnradio2">
                우선특매
              </label>

              <input type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off" value="2"
                     v-model="eventType" @change="getEventGroupList"/>
              <label class="btn btn-outline-secondary" for="btnradio3">
                최우선특매
              </label>
            </div>

            <div v-if="eventGroupList.length === 0">
              <div class="card" style="display: flex; align-items: center;border: none; box-shadow: none;border-radius: 0.25rem; margin: 0 0 20px; height: 154px;">
                <div class="card-body"
                     style="margin: 14px; display: flex; justify-content: center; align-items: center">
                  <div>해당 그룹에 진행 중인 특매행사가 없습니다.</div>
                </div>
              </div>
            </div>

            <div v-if="eventGroupList.length > 0">
              <!-- 특매그룹목록 -->
              <div v-for="evl in eventGroupList" :key="evl.idx">
                <div class="card"
                     style="display: flex;align-items: center;box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);border-radius: 0.25rem; margin: 0 0 20px;"
                     v-if="selectedEVL.pos_bg_code !== evl.pos_bg_code">
                  <div class="card-body" style="box-sizing: border-box; width: 100%;"
                       v-on:click="setEventListDetail(evl)">
                    <div style="margin-bottom: 14px">
                      <div class="square-switch" style="display:flex; float: right; margin: 0">
                        <input v-model="evl.visible" class="form-check-input" type="checkbox" v-on:click="noBubbling"
                               switch="none" :id="`switch1${evl.toprank_event_srl}`" @change="setCondition(evl)"/>

                        <label :for="`switch1${evl.toprank_event_srl}`" data-on-label="노출" data-off-label="숨김"
                               class="mb-0" v-on:click="noBubbling"/>
                      </div>
                      <label class="form-check-label">
                        [{{ evl.bg_target_name }}특매]
                      </label><br/>
                      <label class="form-check-label" style="font-size: 1.2em; font-weight: bold">
                        {{ evl.title }}
                      </label><br/>
                      <label class="form-check-label" style="font-size: 0.8em">
                        ({{ evl.start_date }} - {{ evl.end_date }})
                      </label><br/>
                    </div>
                    <div style="display: inline">
                      <input style="
                            width: 45px;
                            height: 30px;
                            text-align: center;
                            display: inline;
                            vertical-align: middle;
                          " class="form-control" v-on:click="noBubbling()" :placeholder="`${evl.idx}`"
                             :value="placeholderValue" onkeyup="this.value=this.value.replace(/[^0-9]/g,'')"
                             :ref="`ordering${evl.toprank_event_srl}`"/>
                      <span class="input-group-addon" style="font-size: 12px">
                          번째로
                        </span>
                      <span class="button" style="display: inline; vertical-align: middle">
                          <b-button style="
                              height: 30px;
                              text-align: center;
                              display: inline;
                              vertical-align: middle;
                              align-self: center;
                            "
                                    @click=" seqModify(evl.toprank_event_srl, evl.idx, findId(`ordering${evl.toprank_event_srl}`))">
                            변경
                          </b-button>
                        </span>
                    </div>
                  </div>
                </div>
                <div class="card"
                     style="display: flex;align-items: center;box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);border-radius: 0.25rem;background: #74788d;margin: 0 0 20px;"
                     v-if="selectedEVL.pos_bg_code === evl.pos_bg_code">
                  <div class="card-body" style="width: 100%" v-on:click="setEventListDetail(evl)">
                    <div style="margin-bottom: 14px">
                      <div class="square-switch" style="display:flex; float: right; margin: 0">
                        <input v-model="evl.visible" class="form-check-input" type="checkbox" v-on:click="noBubbling"
                               switch="none" :id="`switch2${evl.toprank_event_srl}`" @change="setCondition(evl)"/>
                        <label :for="`switch2${evl.toprank_event_srl}`" data-on-label="노출" data-off-label="숨김"
                               class="mb-0" v-on:click="noBubbling"/>

                      </div>
                      <label class="form-check-label" style="color: white">[{{ evl.bg_target_name }}특매]</label><br/>
                      <label class="form-check-label" style="
                            font-size: 1.2em;
                            font-weight: bold;
                            color: white;
                          ">{{ evl.title }}</label><br/>
                      <label class="form-check-label" style="font-size: 0.8em; color: white">({{ evl.start_date }} -
                        {{ evl.end_date }})</label><br/>
                    </div>
                    <div style="display: inline">
                      <input style="
                            width: 45px;
                            height: 30px;
                            text-align: center;
                            display: inline;
                            vertical-align: middle;
                          " class="form-control" v-on:click="noBubbling()" :placeholder="`${evl.idx}`"
                             :value="placeholderValue" onkeyup="this.value=this.value.replace(/[^0-9]/g,'')"
                             :ref="`itemOrdering${evl.toprank_event_srl}`"/>
                      <span class="input-group-addon" style="font-size: 12px; color: white">
                          번째로
                        </span>
                      <span class="button" style="display: inline; vertical-align: middle">
                          <b-button style="
                              height: 30px;
                              text-align: center;
                              display: inline;
                              vertical-align: middle;
                              align-self: center;
                              background-color: #00000080;
                            "
                                    v-on:click="seqModify(evl.toprank_event_srl,evl.idx,findId(`itemOrdering${evl.toprank_event_srl}`))">
                            변경
                          </b-button>
                        </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end card -->
      <!-- 기본정보 영역 END-->
      <!-- 상품순서 -->
      <div class="col-md-6-5">
        <!-- 순서가 정해진 상품목록 -->

        <div class="card">
          <div class="card-header">
            <label style=" margin-bottom: 0 !important; font-size: 1.3em;font-weight: bold;"
                   v-if="typeof this.selectedEVL.title != 'undefined'">
              {{ this.selectedEVL.title }} - TOP20 상품목록
            </label>

            <label style="
                margin-bottom: 0 !important;
                font-size: 1.3em;
                font-weight: bold;
              " v-if="typeof this.selectedEVL.title == 'undefined'">
              좌측 특매그룹에서 변경할 특매그룹을 선택해주세요.
            </label>
          </div>

          <div class="card-body" style="padding: 20px 20px 0 8px">
            <div v-if="typeof selectedEVL.title === 'undefined' && selectedEVLitemsInRank.length === 0"
                 style="margin-bottom: 100px"/>
            <div v-if="typeof selectedEVL.title !== 'undefined' && selectedEVLitemsInRank.length === 0">
              <div style="display: flex; justify-content: center; align-items: center; height: 140px; margin-bottom: 20px;">
                <p class="m-0">해당 특매에 순서가 정해진 상품이 없습니다.</p>
              </div>
            </div>

            <div class="row col-12" style="margin-right: 0; margin-left: 0"
                 v-if="typeof selectedEVL.title !== 'undefined' && selectedEVLitemsInRank.length > 0">
              <!-- 특매그룹목록 -->
              <div class="col-xl-3 col-lg-3 col-md-3" style="display: inline; padding: 0"
                   v-for="sevl in selectedEVLitemsInRank" :key="sevl.seq">
                <div style="margin-left: 12px">
                  <div class="card" style="
                        align-items: center;
                        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
                        border-radius: 0.25rem;
                      ">
                    <div class="card-body" style="padding: 0.75rem; width: 100%">
                      <div class="flex-shrink-0 pdImgWrap" :class="{isSoldOut : sevl.soldout === 'Y'}">
                        <img v-if="sevl.img_path.length > 0" :src="`${sevl.img_path}`" :class="{grayFilter : sevl.soldout === 'Y'}"
                             style="width: 100%;height: 100%;border-radius: 0.25rem; object-fit: cover;"/>
                        <img v-if="sevl.img_path.length === 0" :src="`${noImg}`"
                             style="width: 130px; height: 130px; border-radius: 0.25rem; object-fit: cover;"/>
                      </div>
                      <hr/>
                      <div class="mb-1">
                        <div
                            v-if=" sevl.badge_limit === 'Y' || sevl.badge_manager === 'Y' ||sevl.badge_multi === 'Y'">
                          <img v-if="sevl.badge_limit === 'Y'" :src="require('@/assets/mcimages/marker_limit@3x.png')"
                               style="margin-right: 4px; height: 16px"/>
                          <img v-if="sevl.badge_manager === 'Y'"
                               :src="require('@/assets/mcimages/marker_best@3x.png')"
                               style="margin-right: 4px; height: 16px"/>
                          <img v-if="sevl.badge_multi === 'Y'"
                               :src="require('@/assets/mcimages/marker_not_sale@3x.png')" style="height: 16px"/>
                          <br/>
                        </div>
                        <label class="form-check-label ellipsis" style="font-weight: bold">
                          {{ sevl.doha_pname.replaceAll("_", " ") }}
                        </label><br/>
                        <label class="form-check-label ellipsis" v-if="sevl.comment.length !== 0">
                          {{ sevl.comment }}
                        </label><br/>
                        <label class="form-check-label" style="font-size: 1em;color: #a0a0a0; margin-bottom: 6px;">
                          {{ sevl.barcode }}
                        </label><br/>
                        <label class="form-check-label"
                               style="font-size: 1.1em;color: black;font-weight: bold;margin-right: 4px;">
                          {{ sevl.saleprice | comma }}원
                        </label>
                        <label class="form-check-label"
                               style="font-size: 0.9em; text-decoration: line-through;color: #a0a0a0;margin-right: 4px;"
                               v-if="sevl.percent !== 0">
                          {{ sevl.price | comma }}원
                        </label>
                        <label class="form-check-label" style="font-size: 0.9em; color: red"
                               v-if="sevl.percent !== 0">
                          {{ sevl.percent }}%
                        </label><br/>
                      </div>

                      <div class="row m-0 p-0 mb-2" style="display: flex;justify-content: space-between; width: 100%; align-items: center;">
                        <div class="p-0 col-4" style="margin-top: 4px; position: relative;display: flex;justify-content: space-between;width: 100%; align-items: center;">
                          <input style="height: 30px;text-align: center; display: inline; vertical-align: middle;" class="form-control" :placeholder="`${sevl.seq}`" onkeyup="this.value=this.value.replace(/[^0-9]/g,'')" :value="placeholderValue" :ref="`ordering${sevl.toprank_srl}`"/>
                          <div class="input-group-addon col-4" style="font-size: 12px; text-align: center">번째로</div>
                          <b-button class="col-4" @click="seqModifyInSEVL(sevl.toprank_srl, sevl.seq, findId(`ordering${sevl.toprank_srl}`))">
                            변경
                          </b-button>
                        </div>
                      </div>
                      <div>
                        <b-button variant="primary"
                                  style="width: 50%; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; border-radius: 0.25rem 0 0 0.25rem;"
                                  v-b-modal="`info${sevl.toprank_srl}`" @click="noBubbling">
                          정보변경
                        </b-button>
                        <b-modal centered :id="`info${sevl.toprank_srl}`" title="정보변경" body-class="p-0 m-0"
                                 hide-footer>
                          <div class="modal-body">
                            <div class="row mb-1">
                              <label class="col-sm-2 col-form-label">
                                배지선택
                              </label>
                              <div class="col-sm-10 col-form-label">
                                <div style="display: inline; margin-right: 10px">
                                  <input class="form-check-input" style="margin-right: 6px" type="checkbox" id="limit"
                                         v-model="sevl.tmp_limit"/>
                                  <label for="limit">
                                    <img :src="require('@/assets/mcimages/marker_limit@3x.png')"
                                         class="form-check-label" style="height: 16px;position: relative;top: -1.2px;"
                                         for="limit"/>
                                  </label>
                                </div>

                                <div style="display: inline; margin-right: 10px">
                                  <input class="form-check-input" style="margin-right: 6px" type="checkbox"
                                         id="manager" v-model="sevl.tmp_manager"/>
                                  <label for="manager">
                                    <img :src="require('@/assets/mcimages/marker_best@3x.png')"
                                         class="form-check-label"
                                         style=" height: 16px; position: relative; top: -1.2px;"/>
                                  </label>
                                </div>

                                <div style="display: inline; margin-right: 10px">
                                  <input class="form-check-input" style="margin-right: 6px" type="checkbox"
                                         id="notSale" v-model="sevl.tmp_multi"/>
                                  <label for="notSale">
                                    <img :src="require('@/assets/mcimages/marker_not_sale@3x.png')"
                                         class="form-check-label"
                                         style="height: 16px;position: relative;top: -1.2px;"/>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <label class="col-sm-2 col-form-label">
                                한줄글
                              </label>
                              <div class="col-sm-10">
                                <input class="form-control" placeholder="한줄글을 입력해주세요." v-model="sevl.comment"/>
                              </div>
                            </div>
                          </div>

                          <div class="modal-footer" style="display: flex; justify-content: space-between">
                            <b-button @click="confirmSoldOut(sevl)">
                              {{ sevl.soldout === 'Y' ? '품절해제' : '품절' }}
                            </b-button>

                            <div style="display: flex; justify-content: space-between">
                              <b-button @click="$bvModal.hide(`info${sevl.toprank_srl}`)" style="margin-right: 10px;">
                                취소
                              </b-button>
                              <b-button variant="primary"
                                        @click="editItem(sevl, 'ranked', `info${sevl.toprank_srl}`)">
                                변경
                              </b-button>
                            </div>
                          </div>
                        </b-modal>

                        <b-button variant="light" style="width: 50%;border-radius: 0 0.25rem 0.25rem 0;" @click="deleteTheProduct(sevl.toprank_srl)">
                          삭제
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 선택되지 않은 상품목록 -->
        <div class="card">
          <div class="card-header" style="display: flex">
            <div class="col-md-6" style="margin-bottom: 0 !important; display: flex; justify-content: flex-start; align-items: center">
              <label style="
                    font-size: 1.3em;
                    font-weight: bold;
                    display: inline-flex;
                    margin-bottom: 0 !important;
                  ">
                TOP20 상품목록 이외의 상품
              </label>
            </div>
            <div class="col-md-6">
              <div class="col-md-12 input-group" style="display: inline-flex; vertical-align: middle"
                   v-if="typeof this.selectedEVL.title != 'undefined'">
                <input type="text" class="form-control" style="display: inline-flex; width: 30%"
                       placeholder="상품명 또는 바코드를 입력해주세요." aria-label="search result" aria-describedby="button-search"
                       :value="searchvalue" ref="searchKeyword" @keypress.enter="searchProduct"/>
                <button class="btn btn-primary" style="display: inline-flex" type="button" id="button-search"
                        v-on:click="searchProduct">
                  검색
                </button>
              </div>
            </div>
          </div>

          <div class="card-body" style="padding-bottom: 0">
            <div v-if="typeof selectedEVL.title === 'undefined' &&selectedEVLitemsNotInRank.length === 0"
                 style="margin-bottom: 100px"/>
            <div v-if="typeof selectedEVL.title !== 'undefined' &&selectedEVLitemsNotInRank.length === 0">
              <div style="display: flex; justify-content: center; align-items: center; height: 140px; margin-bottom: 20px;">
                <p class="m-0">해당 특매에 등록된 상품이 없습니다.</p>
              </div>
            </div>
            <div class="row col-12" style="width: auto"
                 v-if="typeof selectedEVL.title !== 'undefined' && selectedEVLitemsNotInRank.length > 0">
              <!-- 특매그룹목록 -->
              <div class="col-xl-6 col-lg-6 col-md-12" style="display: inline-block" v-for="(nsevl, index) in selectedEVLitemsNotInRank" :key="index">
                <b-card no-body class="overflow-hidden">
                  <div class="card-body" style="padding: 8px" @click="putItemInRank(nsevl.mproduct_event_srl)">
                    <div class="d-flex align-items-center">
                      <div class="flex-shrink-0">
                        <div class="pdImgWrap" :class="{isSoldOut : nsevl.soldout === 'Y'}">
                          <img v-if="nsevl.img_path.length > 0" :src="`${nsevl.img_path}`" :class="{grayFilter : nsevl.soldout === 'Y'}" style="width: 100px; height: 100px; border-radius: 0.25rem; object-fit: cover;"/>
                          <img v-if="nsevl.img_path.length === 0" :src="`${noImg}`" style="width: 100px;height: 100px;border-radius: 0.25rem;object-fit: cover;"/>
                        </div>

                        <div style="margin-top: 4px">
                          <b-button block variant="primary" size="sm" style="width: 100%" @click="noBubbling"
                                    v-b-modal="`nsevl${nsevl.mproduct_srl}`">
                            정보변경
                          </b-button>
                        </div>
                      </div>

                      <b-modal centered :id="`nsevl${nsevl.mproduct_srl}`" title="정보변경" body-class="p-0 m-0" hide-footer>
                        <div class="modal-body">
                          <div class="row mb-1">
                            <label class="col-sm-2 col-form-label">
                              배지선택
                            </label>
                            <div class="col-sm-10 col-form-label">
                              <div style="display: inline; margin-right: 10px">
                                <input class="form-check-input" style="margin-right: 6px" type="checkbox" id="limit"
                                       v-model="nsevl.tmp_limit"/>
                                <label for="limit">
                                  <img :src="require('@/assets/mcimages/marker_limit@3x.png')" class="form-check-label"
                                       style="
                                      height: 16px;
                                      position: relative;
                                      top: -1.2px;
                                    " for="limit"/>
                                </label>
                              </div>

                              <div style="display: inline; margin-right: 10px">
                                <input class="form-check-input" style="margin-right: 6px" type="checkbox" id="mmanager"
                                       v-model="nsevl.tmp_manager"/>
                                <label for="mmanager">
                                  <img :src="require('@/assets/mcimages/marker_best@3x.png')" class="form-check-label"
                                       style="
                                      height: 16px;
                                      position: relative;
                                      top: -1.2px;
                                    "/>
                                </label>
                              </div>

                              <div style="display: inline; margin-right: 10px">
                                <input class="form-check-input" style="margin-right: 6px" type="checkbox" id="nnotSale"
                                       v-model="nsevl.tmp_multi"/>
                                <label for="nnotSale">
                                  <img :src="require('@/assets/mcimages/marker_not_sale@3x.png')"
                                       class="form-check-label" style="
                                      height: 16px;
                                      position: relative;
                                      top: -1.2px;
                                    "/>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <label class="col-sm-2 col-form-label">
                              한줄글
                            </label>
                            <div class="col-sm-10">
                              <input class="form-control" placeholder="한줄글을 입력해주세요." v-model="nsevl.comment"/>
                            </div>
                          </div>
                        </div>

                        <div class="modal-footer" style="display: flex; justify-content: space-between">
                          <b-button @click="confirmSoldOut(nsevl, false)">
                            {{ nsevl.soldout === 'Y' ? '품절해제' : '품절' }}
                          </b-button>

                          <div style="display: flex; justify-content: space-between">
                            <b-button @click="$bvModal.hide(`nsevl${nsevl.mproduct_srl}`)" style="margin-right: 10px;">
                              취소
                            </b-button>
                            <b-button variant="primary" @click="editItem(nsevl, 'unranked', `nsevl${nsevl.mproduct_srl}`)">
                              변경
                            </b-button>
                          </div>
                        </div>
                      </b-modal>

                      <table style="width: 100%">
                        <tr>
                          <td style="padding-left: 1em; vertical-align: top">
                            <!--// 중앙-->
                            <div style="font-size: 14px; display: inline">
                              <div
                                  v-if=" nsevl.badge_limit === 'Y' || nsevl.badge_manager === 'Y' || nsevl.badge_multi === 'Y'">
                                <img v-if="nsevl.badge_limit === 'Y'"
                                     :src="require('@/assets/mcimages/marker_limit@3x.png')"
                                     style="margin-right: 4px; height: 16px"/>
                                <img v-if="nsevl.badge_manager === 'Y'"
                                     :src="require('@/assets/mcimages/marker_best@3x.png')"
                                     style="margin-right: 4px; height: 16px"/>
                                <img v-if="nsevl.badge_multi === 'Y'"
                                     :src="require('@/assets/mcimages/marker_not_sale@3x.png')" style="height: 16px"/>
                                <br/>
                              </div>
                              <label class="form-check-label" style="font-weight: bold">
                                {{ nsevl.doha_pname.replaceAll("_", " ") }}
                              </label><br/>
                              <label class="form-check-label" style="font-size: 1em">
                                {{ nsevl.comment }}
                              </label><br/>
                              <label class="form-check-label" style="
                                  font-size: 1em;
                                  color: #a0a0a0;
                                  margin-bottom: 6px;
                                ">
                                {{ nsevl.barcode }}
                              </label><br/>
                              <label class="form-check-label" style="
                                  font-size: 1.1em;
                                  color: black;
                                  font-weight: bold;
                                  margin-right: 4px;
                                ">
                                {{ nsevl.saleprice | comma }}원
                              </label>
                              <label class="form-check-label"
                                     style=" font-size: 0.9em; text-decoration: line-through; color: #a0a0a0; margin-right: 4px;"
                                     v-if="nsevl.percent !== 0">
                                {{ nsevl.price | comma }}원
                              </label>
                              <label class="form-check-label" style="font-size: 0.9em; color: red"
                                     v-if="nsevl.percent !== 0">
                                {{ nsevl.percent }}%
                              </label><br/>
                              <small class="text-muted" style="font-size: 14px"/>
                            </div>
                          </td>

                          <td style="width: 1em; text-align: right"/>
                          <!--// 우측-->
                        </tr>
                      </table>
                    </div>
                  </div>
                </b-card>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 상품순서 END -->
      <!-- 미리보기 iframe -->
      <div class="col-md-3">
        <div class="card">
          <div class="card-body">
            <iframe style="width: 100%; height: 900px" :src="`${url}`" ref="iframe"/>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-inline-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>


<style>
span.button > button.btn {
  padding: 0 0;
  width: 3rem;
}

@media (min-width: 768px) {
  .col-md-6-5 {
    flex: 0 0 auto;
    width: 55%;
  }

  .col-md-2-5 {
    flex: 0 0 auto;
    width: 20%;
  }
}
</style>
